<template>
  <div id="ai-services_vca_video-segmentation">
    <service-template
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :run-disabled="currentExample === null"
      :icon="require('@/assets/images/icons/services/header/VCA-SEG.svg')"
      type="video"
      service="vca"
    >
      <template v-slot:header>
        {{ $t('services.videoSegmentation.header') }}
      </template>
      <template v-slot:left>
        <carousel
          :step="1"
          :title="$t('services.carousel.videoTitle')"
          type="video"
        >
          <span
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            class="example"
            @click="() => onExampleClick(index)"
          >
            <video
              :src="`${example}#t=0.01`"
              muted
              preload="metadata"
              class="example"
            />
          </span>
        </carousel>
        <upload
          file-type="video"
          :file-size="2"
          :example-file="examples[currentExample]"
          :handle-remove="onUploadRemove"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.videoSegmentation.header')"
          :loading="loading"
        >
          <video
            v-if="result"
            :src="result"
            controls
            autoplay
            playsinline
            muted
            preload="metadata"
            class="video"
          />
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';

export default {
  name: 'VideoSegmentation',
  components: {
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
  }),
  computed: {
    examples() {
      return [
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614167870.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614167976.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614168100.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614168185.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614168234.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614168340.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/seg/1614168836.mp4`,
      ];
    },
  },
  watch: {
    currentExample() {
      this.result = null;
    },
  },
  methods: {
    run() {
      this.loading = true;
      return this.$api.vca.videoSegmentation.segmentVideo(this.examples[this.currentExample]);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      this.currentExample = index;
    },
    onUploadRemove() {
      this.result = null;
      this.currentExample = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .video {
    width: 100%;
    height: 100%;
  }

  #ai-services_vca_video-segmentation {
    background: $background-secondary;

    .example {
      height: 100%;
      cursor: pointer;
    }
  }
</style>
